import clsx from "clsx";
import React from "react";
import { H2, Paragraph } from "~/components/ui/typography";
import type { Logo } from "~/components/logo-list";
import { BlockContent } from "~/components/block-content";
import type { Step } from "./steps";
import {
	Steps,
	Tools,
	FlinkSvg,
	PgSvg,
	OpensearchSvg,
	ClickhouseSvg,
	GrafanaSvg,
	KafkaEcosystemSvg,
} from ".";
import type { SimplePortableText } from "~/types/sanity-schema";
import { useMedia } from "react-use";
import { Image } from "~/components/ui/image";
import mysqlDiagram from "~/images/diagram-mysql.png";
import kafkaConnectDiagram from "~/images/diagram-kafka-connect.png";
import kafkaConnectDiagramMobile from "~/images/diagram-kafka-connect-mobile.png";
import m3Diagram from "~/images/diagram-m3.png";
import m3DiagramMobile from "~/images/diagram-m3-mobile.png";
import metricsDiagram from "~/images/diagram-metrics.png";
import metricsDiagramMobile from "~/images/diagram-metrics-mobile.png";

interface ServiceDiagramProps {
	variant:
		| "flink"
		| "kafka-ecosystem"
		| "opensearch"
		| "pg"
		| "clickhouse"
		| "grafana"
		| "mysql"
		| "kafka-connect"
		| "m3"
		| "redis"
		| "cassandra"
		| "metrics";
	tagline?: string;
	title?: string;
	subtitle?: SimplePortableText;
	steps: Step[];
	resources?: SimplePortableText;
	toolsTitle?: string;
	tools?: Logo[];
}

const isInteractiveSvg = (variant: string) => {
	switch (variant) {
		case "kafka-ecosystem":
		case "flink":
		case "pg":
		case "opensearch":
		case "clickhouse":
		case "grafana":
		case "metrics":
			return true;
		default:
			return false;
	}
};

const hasSvg = (variant: string) => {
	switch (variant) {
		case "redis":
		case "cassandra":
			return false;
		default:
			return true;
	}
};

const DiagramSvg = (
	variant: string,
	activeStep: string,
	handleClick: (name: string) => void
) => {
	const isMd = useMedia("(max-width: 768px)", false);

	switch (variant) {
		case "kafka-ecosystem":
			return (
				<KafkaEcosystemSvg
					onStepChange={handleClick}
					currentStep={activeStep}
				/>
			);
		case "flink":
			return <FlinkSvg onStepChange={handleClick} currentStep={activeStep} />;
		case "pg":
			return <PgSvg onStepChange={handleClick} currentStep={activeStep} />;
		case "opensearch":
			return (
				<OpensearchSvg onStepChange={handleClick} currentStep={activeStep} />
			);
		case "clickhouse":
			return (
				<ClickhouseSvg onStepChange={handleClick} currentStep={activeStep} />
			);
		case "grafana":
			return <GrafanaSvg onStepChange={handleClick} currentStep={activeStep} />;
		case "mysql":
			return (
				<Image
					src={mysqlDiagram}
					alt="Aiven for MySQL diagram"
					aria-hidden="true"
				/>
			);
		case "kafka-connect":
			return (
				<Image
					src={isMd ? kafkaConnectDiagramMobile : kafkaConnectDiagram}
					alt="Aiven for Kafka Connect diagram"
					aria-hidden="true"
				/>
			);
		case "m3":
			return (
				<Image
					src={isMd ? m3DiagramMobile : m3Diagram}
					alt="Aiven for M3 diagram"
					aria-hidden="true"
				/>
			);
		case "metrics":
			return (
				<Image
					src={isMd ? metricsDiagramMobile : metricsDiagram}
					alt="Aiven for Metrics diagram"
					aria-hidden="true"
				/>
			);
		default:
			return null;
	}
};

export function ServiceDiagram({
	variant = "kafka-ecosystem",
	tagline,
	title,
	subtitle,
	steps,
	resources,
	toolsTitle,
	tools,
}: ServiceDiagramProps) {
	const [activeStep, setActiveStep] = React.useState("step1");
	const [isModalOpen, setModalOpen] = React.useState(false);

	const isLg = useMedia("(max-width: 1024px)", false);

	const handleClick = (name: string) => {
		setActiveStep(name);
		if (isLg) {
			setModalOpen(true);
		}
	};

	return (
		<>
			{tagline || title || subtitle ? (
				<div className="mx-auto max-w-content text-center">
					{tagline ? (
						<Paragraph size="overline" className="mb-5" color="tagline">
							{tagline}
						</Paragraph>
					) : null}
					{title ? <H2 className="mb-5">{title}</H2> : null}
					{subtitle ? <BlockContent value={subtitle} /> : null}
				</div>
			) : null}
			<div className="grid gap-layout2 pt-layout4 lg:grid-cols-[65%_33%]">
				{hasSvg(variant) ? (
					<div
						className={clsx(
							"border-stroke bg-primary order-1 flex items-center justify-center rounded-lg border p-layout2 lg:py-0",
							{
								"row-span-2": tools && tools.length > 0,
							}
						)}
					>
						{DiagramSvg(variant, activeStep, handleClick)}
					</div>
				) : null}
				<Steps
					className={clsx("order-3 lg:order-2", {
						"row-span-2": !hasSvg(variant),
					})}
					stepItems={steps}
					currentStep={activeStep}
					stepState={setActiveStep}
					isModalOpen={isModalOpen}
					closeModal={() => setModalOpen(false)}
					isInteractiveSvg={isInteractiveSvg(variant)}
					minHeight={
						isInteractiveSvg(variant) ? "lg:min-h-[710px]" : "lg:min-h-[100%]"
					}
				/>
				{tools ? (
					<Tools
						className="border-stroke bg-primary order-2 rounded-lg border p-layout2 lg:order-3"
						title={toolsTitle}
						logoItems={tools}
					/>
				) : null}
				{!hasSvg(variant) && resources ? (
					<div className="border-stroke bg-primary order-4 rounded-lg border p-layout2">
						<BlockContent value={resources.content} />
					</div>
				) : null}
			</div>
		</>
	);
}
