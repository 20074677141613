import clsx from "clsx";
import type { Cta } from "~/types/index";
import { H2, Paragraph, Blockquote } from "~/components/ui/typography";
import { CtaButtonGroup } from "~/components/ui/button";
import { BlockContent } from "~/components/block-content";
import type { SimplePortableText } from "~/types/sanity-schema";
import { Image } from "~/components/ui/image";
import Quote from "~/images/shape-quote.svg";

interface Props {
	tagline?: string;
	title: string;
	subtitle?: SimplePortableText;
	columnOne?: SimplePortableText;
	columnTwo?: SimplePortableText;
	primaryCTA?: Cta;
	secondaryCTA?: Cta;
	tertiaryCTA?: Cta;
	variant?: "oneColumn" | "twoColumn" | "centerColumn";
}

export function TextColumn({
	tagline,
	title,
	subtitle,
	columnOne,
	columnTwo,
	primaryCTA,
	secondaryCTA,
	tertiaryCTA,
	variant = "oneColumn",
}: Props) {
	return (
		<>
			<div
				className={clsx("max-w-content", {
					"mx-auto text-center": variant === "centerColumn",
				})}
			>
				{tagline ? (
					<Paragraph size="overline" className="mb-5" color="tagline">
						{tagline}
					</Paragraph>
				) : null}
				{title ? <H2 className="mb-5">{title}</H2> : null}
				{subtitle ? (
					<BlockContent
						blockOverrides={{
							blockquote: ({ children }) => (
								<Blockquote className="rounded-lg bg-theme-secondary text-center text-lg text-secondary-90">
									<Image
										className="mx-auto pb-6"
										width="44px"
										height="33px"
										src={Quote}
										alt=""
									/>
									{children}
								</Blockquote>
							),
						}}
						value={subtitle}
					/>
				) : null}
			</div>
			{(columnOne || columnTwo) && variant != "centerColumn" ? (
				<div
					className={clsx("mt-5", {
						"flex flex-col gap-layout4 md:flex-row": variant === "twoColumn",
						"max-w-content": variant != "twoColumn",
					})}
				>
					{columnOne ? (
						<div className="flex-1">
							<BlockContent value={columnOne} />
						</div>
					) : null}
					{columnTwo ? (
						<div className="flex-1">
							<BlockContent value={columnTwo} />
						</div>
					) : null}
				</div>
			) : null}
			{(primaryCTA && primaryCTA.title) ||
			(secondaryCTA && secondaryCTA.title) ||
			(tertiaryCTA && tertiaryCTA.title) ? (
				<div
					className={clsx("mt-6 flex flex-col flex-wrap gap-5 md:flex-row", {
						"justify-center": variant === "centerColumn",
					})}
				>
					<CtaButtonGroup
						primaryCTA={primaryCTA}
						secondaryCTA={secondaryCTA}
						tertiaryCTA={tertiaryCTA}
					/>
				</div>
			) : null}
		</>
	);
}
