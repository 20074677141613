import clsx from "clsx";
import React from "react";
import { Hero } from "./sections/hero";
import { TextImage } from "./sections/text-image";
import { TextVideo } from "./sections/text-video";
import { TextColumn } from "./sections/text-column";
import { IconList } from "./sections/icon-list";
import { LogoList } from "./sections/logo-list";
import { CardList } from "./sections/card-list";
import { Image } from "./sections/image";
import { Iframe } from "./sections/iframe";
import { Accordion } from "./sections/accordion";
import { Testimonial } from "./sections/testimonial";
import { ScrollSpyNav } from "./sections/scrollspy-nav";
import { ServiceList } from "./sections/service-list";
import { ServiceDiagram } from "./sections/service-diagram";
import { LocationList } from "./sections/location-list";
import { Pricing } from "./sections/pricing";
import { AvatarList } from "./sections/avatar-list";
import { RelatedBlogs } from "./sections/related-blogs";
import { NumberList } from "./sections/number-list";
import { InfoBanner } from "./sections/info-banner";
import { EventList } from "./sections/event-list";
import { Tab } from "./sections/tab";
import { SocialMediaShare } from "./sections/social-media-share";
import { isDevelopment, isGradientColor } from "~/utils/misc";
import type { PageSingleQuery } from "~/types/queries";
import { Container } from "~/components/ui/container";
import { getImageBuilder } from "~/utils/images";
import type { PricingRelatedData } from "~/types/product";
import { useMobile } from "~/utils/hooks";
import type { PostPreview } from "~/types/post";
import { MarkdownSection } from "./sections/markdown";
import { Agenda } from "./sections/agenda";
import { ImageLinkSlider } from "./sections/image-link-slider";
import { EventSessionList } from "./sections/event-session-list";
import { Arcade } from "./sections/arcade";
import { Marketecture } from "./sections/marketecture";

export type AdditionalSectionData = {
	pricingRelatedData?: PricingRelatedData;
	relatedPostData?: Array<PostPreview>;
	supportedVersion?: string;
	externalId?: string;
};

function resolveSections(section: PageSingleQuery["content"][number]) {
	switch (section._type) {
		case "hero":
			return Hero;
		case "textImage":
			return TextImage;
		case "textVideo":
			return TextVideo;
		case "textColumn":
			return TextColumn;
		case "iconList":
			return IconList;
		case "logoList":
			return LogoList;
		case "cardList":
			return CardList;
		case "eventList":
			return EventList;
		case "coverImage":
			return Image;
		case "iframe":
			return Iframe;
		case "accordion":
			return Accordion;
		case "testimonial":
			return Testimonial;
		case "serviceList":
			return ServiceList;
		case "scrollSpyNavigation":
			return ScrollSpyNav;
		case "serviceDiagram":
			return ServiceDiagram;
		case "locationList":
			return LocationList;
		case "pricing":
			return Pricing;
		case "avatarList":
			return AvatarList;
		case "relatedBlogs":
			return RelatedBlogs;
		case "numberList":
			return NumberList;
		case "infoBannerSection":
			return InfoBanner;
		case "socialMediaShare":
			return SocialMediaShare;
		case "tab":
			return Tab;
		case "agenda":
			return Agenda;
		case "markdownSection":
			return MarkdownSection;
		case "carouselSlider":
			return ImageLinkSlider;
		case "eventSessionList":
			return EventSessionList;
		case "arcade":
			return Arcade;
		case "marketecture":
			return Marketecture;
		default:
			console.error("Cant find section"); // eslint-disable-line no-console
	}

	return null;
}

export default function RenderSections({
	sections,
	additionalData,
	compact,
}: {
	sections: PageSingleQuery["content"] | undefined;
	additionalData?: AdditionalSectionData;
	compact?: boolean;
}) {
	const isMobile = useMobile();
	if (!sections) {
		console.warn("Empty page content");
		return isDevelopment() ? <h1>Empty page content</h1> : null;
	}

	return (
		<>
			{sections.length ? (
				<main id="main" tabIndex={-1}>
					{sections.map((section, index: number) => {
						const SectionComponent = resolveSections(section);
						if (!SectionComponent) {
							return (
								<div key={section._key + index}>
									Missing section {section._type}
								</div>
							);
						}

						// We don't want to wrap scrollspy inside <section>
						if (section._type === "scrollSpyNavigation") {
							return section.disabled ? null : (
								<SectionComponent
									// eslint-disable-next-line @typescript-eslint/no-explicit-any
									{...(section as any)}
									key={section._key + index}
								/>
							);
						}

						if (section._type === "hero") {
							return section.disabled ? null : (
								<section
									data-name={section._type}
									className={clsx({
										dark: Boolean(section.darkTheme),
									})}
									key={section._key + index}
								>
									<Hero
										{...section}
										supportedVersion={additionalData?.supportedVersion}
										externalId={additionalData?.externalId}
									/>
								</section>
							);
						}

						const isDarkTheme = Boolean(section.darkTheme);

						const backgroundColor = section.backgroundColor;
						const backgroundImageSource = !isMobile
							? getImageBuilder(section.backgroundImage)
							: getImageBuilder(section.backgroundImageMobile);
						const backgroundImagePosition = !isMobile
							? section.backgroundPosition
							: section.backgroundPositionMobile;
						const backgroundSize = !isMobile
							? section.backgroundSize
							: section.backgroundSizeMobile;
						const isGradientBackground = backgroundColor
							? isGradientColor(backgroundColor)
							: false;
						const bgImage = isGradientBackground
							? backgroundColor
							: backgroundImageSource
								? `url(${backgroundImageSource})`
								: undefined;
						const bgColor =
							backgroundColor && !isGradientBackground
								? backgroundColor
								: undefined;

						// If fullWidth, set background to the parent div
						// else, set background to the container
						const backgroundStyle = {
							backgroundColor: bgColor,
							backgroundImage: bgImage,
							backgroundPosition: backgroundImagePosition,
							backgroundSize: backgroundSize,
						};

						// Support legacy rounded boolean
						const sectionRoundedCorners: string =
							section.roundedCorners === "rounded-none"
								? "rounded-none"
								: section.rounded || section.roundedCorners === "rounded"
									? "rounded-2xl"
									: section.roundedCorners +
										"-2xl lg:" +
										section.roundedCorners +
										"-6xl";

						return section.disabled ? null : (
							<React.Fragment key={section._key + index}>
								<section
									data-name={section._type}
									id={section.id}
									className={clsx(
										{
											dark: isDarkTheme,
										},
										{
											"bg-secondary-90":
												isDarkTheme && section.fullWidth && bgImage,
										},
										{
											"bg-cover bg-no-repeat": section.fullWidth && bgImage,
										},
										{
											[sectionRoundedCorners as string]:
												(section.fullWidth && section.roundedCorners) ||
												undefined,
										}
									)}
									style={section?.fullWidth ? backgroundStyle : {}}
								>
									<Container
										compactPadding={compact}
										className={clsx(
											{
												"mt-layout3 md:mt-layout6": Boolean(
													section.addSpaceTop
												),
											},
											{
												"mb-layout3 md:mb-layout6": Boolean(
													section.addSpaceBottom
												),
											},
											{
												"md:!py-layout4":
													section._type === "logoList" && (bgColor || bgImage), // use case logo list after hero reduce spacing. Refer to https://aiven.io/solutions/aiven-ai-database-optimizer
											},
											{
												"!pt-0": Boolean(section.noSpaceTop),
											},
											{
												"!pb-0": Boolean(section.noSpaceBottom),
											},
											{
												"!pl-0": Boolean(section.noSpaceLeft),
											},
											{
												"!pr-0": Boolean(section.noSpaceRight),
											},
											{
												"lg:-mt-layout4 lg:pb-layout4 lg:pt-0": Boolean(
													section.topOverlap
												),
											},
											{
												// to align non-fullwidth sections using bg-color
												// so their outer side matches with inner side of normal sections
												"!max-w-container md:!p-layout4":
													!section.fullWidth && (bgColor || bgImage),
											},
											{
												"bg-secondary-90":
													isDarkTheme && !section.fullWidth && bgImage,
											}
										)}
										roundedCorners={
											section.rounded || section.roundedCorners
												? sectionRoundedCorners
												: ""
										}
										{...(!section.fullWidth ? backgroundStyle : {})}
									>
										<SectionComponent
											// eslint-disable-next-line @typescript-eslint/no-explicit-any
											{...(section as any)}
											{...additionalData}
										/>
									</Container>
								</section>
							</React.Fragment>
						);
					})}
				</main>
			) : null}
		</>
	);
}
