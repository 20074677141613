import type { Accordion as AccordionType } from "~/types/sanity-schema";
import { Accordion as AccordionComponent } from "../ui/accordion";
import { asText, asLink } from "~/utils/sanity-helpers";
import { BlockContent } from "../block-content";
import {
	getImageAltProp,
	getImageBuilder,
	getImageProps,
} from "~/utils/images";
import clsx from "clsx";
import { Paragraph, H2 } from "~/components/ui/typography";
import type { ImageProps } from "~/components/ui/image";
import { Image } from "~/components/ui/image";
import { CtaButtonGroup } from "~/components/ui/button";

const ImageComponent = (imgProps: ImageProps) => (
	<Image className="mt-6 w-full rounded-lg lg:mx-auto" {...imgProps} />
);

export function Accordion({
	headerPosition = "default",
	tagline,
	title,
	subtitle,
	image,
	primaryCTA,
	secondaryCTA,
	items,
}: AccordionType) {
	const imgProps = getImageProps(
		getImageBuilder(image, { alt: getImageAltProp(image) }),
		{
			widths: [400, 600, 800],
			sizes: ["50vw"],
		}
	);

	const accordionItems = items
		? items.map((item) => {
				const type = item._type;

				switch (type) {
					case "faq": {
						return {
							id: item.id,
							title: asText(item.title),
							panel: item.panelText ? (
								<BlockContent value={item.panelText} />
							) : null,
							defaultOpen: item.open,
						};
					}

					default:
						return {
							iconImage: getImageProps(
								getImageBuilder(item.iconImage, {
									alt: getImageAltProp(item.iconImage, {
										fallback: `${asText(item.title)} icon`,
									}),
								})?.width(24)
							),
							id: item.id,
							title: asText(item.title),
							panel: item.panelText ? (
								<BlockContent value={item.panelText} />
							) : null,
							defaultOpen: item.open,
						};
				}
			})
		: [];

	return (
		<div
			className={clsx("flex flex-col gap-layout5", {
				"mx-auto max-w-content": headerPosition === "default",
				"lg:flex-row [&>div]:flex-1": headerPosition === "left",
			})}
		>
			{title || subtitle ? (
				<div
					className={clsx("text-center", {
						"lg:text-left": headerPosition === "left",
						"max-w-content self-center": headerPosition === "center",
					})}
				>
					{tagline ? (
						<Paragraph size="overline" className="mb-5" color="tagline">
							{tagline}
						</Paragraph>
					) : null}
					{title ? <H2>{title}</H2> : null}
					{subtitle ? (
						<div className="mt-5">
							<BlockContent value={subtitle} />
						</div>
					) : null}
					{primaryCTA || secondaryCTA ? (
						<div
							className={clsx(
								"mt-6 flex flex-col gap-5 md:flex-row md:justify-center",
								{
									"lg:justify-start": headerPosition === "left",
								}
							)}
						>
							<CtaButtonGroup
								primaryCTA={{
									title: primaryCTA?.title,
									caption: primaryCTA?.caption,
									url: asLink(primaryCTA),
									trackingPosition: "accordion",
								}}
								secondaryCTA={{
									title: secondaryCTA?.title,
									caption: secondaryCTA?.caption,
									url: asLink(secondaryCTA),
									trackingPosition: "accordion",
								}}
							/>
						</div>
					) : null}
					{image && image?.asset?.url && headerPosition !== "center"
						? ImageComponent(imgProps)
						: null}
				</div>
			) : null}
			{headerPosition !== "center" ? (
				<AccordionComponent items={accordionItems} />
			) : (
				<div className="flex w-full flex-col items-start gap-layout2 lg:flex-row lg:gap-layout5 [&>div]:flex-1">
					{image && image?.asset?.url ? (
						<div className="w-full">{ImageComponent(imgProps)}</div>
					) : null}
					<div className="w-full">
						<AccordionComponent items={accordionItems} />
					</div>
				</div>
			)}
		</div>
	);
}
