import {
	getImageAltProp,
	getImageBuilder,
	getImageProps,
} from "~/utils/images";
import type { ImgPropsOptions } from "~/utils/images";
import { asLink, asText } from "~/utils/sanity-helpers";
import type { CardList as CardListType } from "~/types/sanity-schema";
import { CardList as CardListComponent } from "../card-list";
import type { CardItem } from "../card-list";
import { useSharedContent } from "~/hooks/localization";
import { notEmpty, formatDate, getReferenceUrl } from "~/utils/misc";
import { Icon } from "~/components/ui/icons";

export function CardList({
	title,
	subtitle,
	tagline,
	items,
	align = "center",
	itemsPerRow = 4,
	headerPosition = "default",
	compact = false,
	primaryCTA,
	secondaryCTA,
	tertiaryCTA,
}: CardListType) {
	const { t } = useSharedContent();

	const imgOptions = (): ImgPropsOptions => {
		return {
			widths: [400, 600, 800],
			sizes: ["(min-width:768px) 300px", "50vw"],
		};
	};

	const cardItems = items
		? items
				.map<CardItem | null>((item) => {
					const type = item._type;

					switch (type) {
						case "cardItem": {
							return {
								image: getImageProps(
									getImageBuilder(item.image, {
										alt: getImageAltProp(item.image, {
											fallback: `${asText(item.title)} illustration`,
										}),
									}),
									imgOptions()
								),
								title: asText(item.title),
								tagline: item.tagline,
								subtitle: item.subtitle,
								variant: item.variant,
								borderTopColor: item.borderTopColor,
								cta: {
									url: asLink(item.ctaLink),
									title: item.ctaLink?.title,
									iconLeft: item.ctaLink?.isPdf ? (
										<Icon name="file" color="primary" />
									) : null,
								},
							};
						}
						case "post": {
							return {
								image: getImageProps(
									getImageBuilder(item.mainImage, {
										alt: getImageAltProp(item.mainImage, {
											fallback: `${asText(item.title)} illustration`,
										}),
									}),
									imgOptions()
								),
								title: asText(item.title),
								subtitle: item.subtitle,
								tagline: t("blog", "Blog"),
								cta: {
									url: getReferenceUrl(item.__i18n_lang, item.slug),
									title: t("readMore"),
								},
							};
						}
						case "devArticle": {
							return {
								image: getImageProps(
									getImageBuilder(item.seo?.metaImage, {
										alt: `${asText(item.title)} illustration`,
									}),
									imgOptions()
								),
								title: asText(item.title),
								subtitle: item.subtitle,
								tagline: t("devCenter", "Developer Center"),
								cta: {
									url: getReferenceUrl(item.__i18n_lang, item.slug),
									title: t("readMore"),
								},
							};
						}
						case "pressRelease": {
							return {
								image: getImageProps(
									getImageBuilder(item.seo?.metaImage, {
										alt: `${asText(item.title)} illustration`,
									}),
									imgOptions()
								),
								title: asText(item.title),
								subtitle: item.subtitle,
								tagline: t("press", "Press"),
								cta: {
									url: getReferenceUrl(item.__i18n_lang, item.slug),
									title: t("readMore"),
								},
							};
						}
						case "whitepaper": {
							return {
								image: getImageProps(
									getImageBuilder(item.seo?.metaImage, {
										alt: `${asText(item.asset.title)} illustration`,
									}),
									imgOptions()
								),
								title: asText(item.asset.title),
								subtitle: item.asset.summary,
								tagline: t("whitepaper", "White paper"),
								cta: {
									url: getReferenceUrl(item.__i18n_lang, item.slug),
									title: t("downloadWhitepaper", "Download white paper"),
								},
							};
						}
						case "ebook": {
							return {
								image: getImageProps(
									getImageBuilder(item.seo?.metaImage, {
										alt: `${asText(item.asset.title)} illustration`,
									}),
									imgOptions()
								),
								title: asText(item.asset.title),
								subtitle: item.asset.summary,
								tagline: t("ebook", "E-book"),
								cta: {
									url: getReferenceUrl(item.__i18n_lang, item.slug),
									title: t("downloadEbook", "Download e-book"),
								},
							};
						}
						case "webinar": {
							return {
								image: getImageProps(
									getImageBuilder(item.seo?.metaImage, {
										alt: `${asText(item.asset.title)} illustration`,
									}),
									imgOptions()
								),
								title: asText(item.asset.title),
								subtitle: item.asset.summary,
								date: item.publishedAt ? formatDate(item.publishedAt) : "",
								tagline: t("webinar", "Webinar"),
								cta: {
									url: getReferenceUrl(item.__i18n_lang, item.slug),
									title: t("watchWebinar", "Watch webinar"),
								},
							};
						}
						case "video": {
							return {
								image: getImageProps(
									getImageBuilder(item.seo?.metaImage, {
										alt: `${asText(item.asset.title)} illustration`,
									}),
									imgOptions()
								),
								title: asText(item.asset.title),
								subtitle: item.asset.summary,
								date: item.publishedAt ? formatDate(item.publishedAt) : "",
								tagline: t("video", "Video"),
								cta: {
									url: getReferenceUrl(item.__i18n_lang, item.slug),
									title: t("watchVideo", "Watch video"),
								},
							};
						}
						case "podcast": {
							return {
								image: getImageProps(
									getImageBuilder(item.seo?.metaImage, {
										alt: `${asText(item.asset.title)} illustration`,
									}),
									imgOptions()
								),
								title: asText(item.asset.title),
								subtitle: item.asset.summary,
								date: item.publishedAt ? formatDate(item.publishedAt) : "",
								tagline: t("podcast", "Podcast"),
								cta: {
									url: getReferenceUrl(item.__i18n_lang, item.slug),
									title: t("watchPodcast", "Watch podcast"),
								},
							};
						}
						case "workshop": {
							return {
								image: getImageProps(
									getImageBuilder(item.seo?.metaImage, {
										alt: `${asText(item.asset.title)} illustration`,
									}),
									imgOptions()
								),
								title: asText(item.asset.title),
								subtitle: item.asset.summary,
								date: item.publishedAt ? formatDate(item.publishedAt) : "",
								tagline: t("workshop", "Workshop"),
								cta: {
									url: getReferenceUrl(item.__i18n_lang, item.slug),
									title: t("watchWorkshop", "Watch workshop"),
								},
							};
						}
						case "report": {
							return {
								image: getImageProps(
									getImageBuilder(item.seo?.metaImage, {
										alt: `${asText(item.asset.title)} illustration`,
									}),
									imgOptions()
								),
								title: asText(item.asset.title),
								subtitle: item.asset.summary,
								tagline: t("report", "Report"),
								cta: {
									url: getReferenceUrl(item.__i18n_lang, item.slug),
									title: t("downloadReport", "Download report"),
								},
							};
						}
						case "caseStudy": {
							return {
								image: getImageProps(
									getImageBuilder(item.image, {
										alt: getImageAltProp(item.image, {
											fallback: `${asText(item.heroTitle)} illustration`,
										}),
									}),
									imgOptions()
								),
								title: asText(item.heroTitle),
								subtitle: item.heroSubtitle,
								tagline: t("caseStudy", "Case study"),
								cta: {
									url: getReferenceUrl(item.__i18n_lang, item.slug),
									title: t("readCaseStudy", "Read case study"),
								},
							};
						}

						default:
							return null;
					}
				})
				.filter(notEmpty)
		: [];

	return (
		<>
			<CardListComponent
				align={align}
				itemsPerRow={headerPosition === "default" ? itemsPerRow : 2}
				items={cardItems}
				title={asText(title)}
				subtitle={subtitle}
				tagline={tagline}
				headerPosition={headerPosition}
				compact={compact}
				primaryCTA={{
					title: primaryCTA?.title,
					caption: primaryCTA?.caption,
					url: asLink(primaryCTA),
					trackingPosition: "card",
					iconLeft: primaryCTA?.isPdf ? <Icon name="file" /> : null,
				}}
				secondaryCTA={{
					title: secondaryCTA?.title,
					caption: secondaryCTA?.caption,
					url: asLink(secondaryCTA),
					trackingPosition: "card",
					iconLeft: secondaryCTA?.isPdf ? <Icon name="file" /> : null,
				}}
				tertiaryCTA={{
					title: tertiaryCTA?.title,
					caption: tertiaryCTA?.caption,
					url: asLink(tertiaryCTA),
					trackingPosition: "card",
					iconLeft: tertiaryCTA?.isPdf ? (
						<Icon name="file" color="primary" />
					) : null,
				}}
			/>
		</>
	);
}
