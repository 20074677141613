import clsx from "clsx";
import type { Cta } from "~/types/index";
import { H2, Paragraph } from "~/components/ui/typography";
import { CtaButtonGroup } from "~/components/ui/button";
import { Youtube } from "~/components/youtube";
import { BlockContent } from "~/components/block-content";
import type { ImageProps } from "~/components/ui/image";
import { Image } from "~/components/ui/image";
import type { SimplePortableText } from "~/types/sanity-schema";
import { WistiaWithTracking } from "~/components/wistia-with-tracking";

interface Props {
	media: "image" | "video";
	image?: ImageProps;
	mobileImage?: ImageProps;
	mobileImageSetting?: "default" | "hide" | "custom";
	videoHosting?: "youtube" | "wistia";
	wistiaId?: string;
	ytId?: string;
	tagline?: string;
	title?: string;
	text?: SimplePortableText;
	primaryCTA?: Cta;
	secondaryCTA?: Cta;
	tertiaryCTA?: Cta;
	variant?: "mediaLeft" | "mediaRight" | "mediaCenter";
	mediaWidth?: string;
	imagePosition?: "top" | "center" | "bottom";
	mediaSticky?: boolean;
}

export function TextMedia({
	media,
	image,
	mobileImage,
	mobileImageSetting = "default",
	videoHosting,
	wistiaId,
	ytId,
	tagline,
	title,
	text,
	primaryCTA,
	secondaryCTA,
	tertiaryCTA,
	variant = "mediaLeft",
	mediaWidth = "default",
	imagePosition = "center",
	mediaSticky = false,
}: Props) {
	const variants = {
		mediaLeft: "lg:flex-row",
		mediaRight: "lg:flex-row-reverse",
		mediaCenter: "!flex-col-reverse text-center",
	};

	const imagePositions = {
		top: "items-start",
		center: "items-center",
		bottom: "items-end",
	};

	const mediaLeftRightWidths: { [key: string]: string } = {
		default: "flex-1",
		sm: "lg:basis-1/2 lg:basis-1/3",
		lg: "lg:basis-1/2 lg:basis-2/3",
	};

	const mediaCenterWidths: { [key: string]: string } = {
		default: "lg:w-1/2 lg:w-2/3",
		sm: "lg:w-1/2",
		lg: "w-full",
	};

	return (
		<div
			className={clsx(
				"flex flex-col gap-layout4",
				variants[variant],
				imagePositions[imagePosition]
			)}
		>
			<div
				className={clsx("w-full lg:mx-auto", {
					[mediaLeftRightWidths[mediaWidth] || mediaLeftRightWidths.default]:
						variant != "mediaCenter",
					[mediaCenterWidths[mediaWidth] || mediaCenterWidths.default]:
						variant === "mediaCenter",
					"lg:self-center": variant === "mediaCenter",
					"w-full": media === "video",
					"lg:sticky lg:top-[104px]": mediaSticky, // top is nav-height 80px + 24px
				})}
			>
				{media === "video" ? (
					videoHosting === "wistia" && wistiaId ? (
						<WistiaWithTracking wistiaId={wistiaId} />
					) : ytId ? (
						<Youtube id={ytId} children={title} image={image} />
					) : null
				) : media === "image" && image?.src ? (
					<>
						<Image
							className={clsx("w-full rounded-lg lg:mx-auto lg:w-auto", {
								"hidden lg:block": mobileImageSetting !== "default",
							})}
							{...image}
						/>
						{mobileImage?.src && mobileImageSetting !== "hide" ? (
							<Image
								className="mx-auto rounded-lg lg:hidden"
								{...mobileImage}
							/>
						) : null}
					</>
				) : null}
			</div>
			<div
				className={clsx("flex flex-1 flex-col justify-center", {
					"mx-auto max-w-content": variant === "mediaCenter",
				})}
			>
				{tagline ? (
					<Paragraph size="overline" className="mb-5" color="tagline">
						{tagline}
					</Paragraph>
				) : null}
				{title ? <H2 className="mb-5">{title}</H2> : null}
				{text ? <BlockContent value={text} /> : null}
				{(primaryCTA && primaryCTA.title) ||
				(secondaryCTA && secondaryCTA.title) ||
				(tertiaryCTA && tertiaryCTA.title) ? (
					<div
						className={clsx("mt-5 flex flex-col flex-wrap gap-5 lg:flex-row", {
							"justify-center": variant === "mediaCenter",
						})}
					>
						<CtaButtonGroup
							primaryCTA={primaryCTA}
							secondaryCTA={secondaryCTA}
							tertiaryCTA={tertiaryCTA}
						/>
					</div>
				) : null}
			</div>
		</div>
	);
}
